import Hero from '../components/top-section/hero';
import Layout from '../components/Layout';
import Mission from '../components/top-section/mission';
import News from '../components/top-section/news';
import React, { useMemo } from 'react';
import Seo from '../components/seo';
import Service from '../components/top-section/service';
import { graphql } from 'gatsby';
import { css } from '@emotion/react';
import { mediaSp } from '../styles/media-query';


import bnr_media_img from '../images/bnr_media_img.png';
import bnr_media_img_sp from '../images/bnr_media_img-sp.png';

export default function Home({ data: { allMicrocmsNews } }) {
  const news = useMemo(() => allMicrocmsNews.nodes, [allMicrocmsNews.nodes]);

  return (
    <>
      <Seo
        url="/"
        title="Uniforce（ユニフォース）株式会社"
        description="Uniforce株式会社の企業情報サイト。「ガバナンスグロース」をミッションを掲げ、企業経営に持続的成長の基盤となるガバナンス構築を行います。「決算開示管理クラウド」や「IPO準備クラウド」などのクラウドサービスとコンサルティングで企業の成長を後押しします。"
        image="https://uniforce.co.jp/og-image.jpg"
        breadcrumbs={[{ label: 'ホーム', url: '/' }]}
      />
      <Layout root breadcrumb={[]}>
        <div style={{ overflow: 'hidden' }}>
          <Hero />
          <Mission />
          <Service />
          <News news={news} />
          <div  css={css`
            padding: 80px 0;
            background-color: #ffffff;
            ${mediaSp} {
              padding: 30px 0;
            }
          `}>
            <div  css={css`
              max-width: 990px;
              margin: auto;
              ${mediaSp} {
                max-width: 305px;
              }
            `}>
              <a href="https://uniforce.co.jp/media/" target="_blank" rel="noreferrer">
                <img css={css`
                  max-width: 100%;width: 100%;
                  ${mediaSp} {
                    display: none;
                  }
                `} src={bnr_media_img} alt="Uniforce+" />
                <img css={css`
                  display: none;
                  ${mediaSp} {
                    display: block;
                    max-width: 100%;width: 100%;
                  }
                `} src={bnr_media_img_sp} alt="Uniforce+" />
              </a>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export const pageQuery = graphql`
  query {
    allMicrocmsNews(limit: 3, sort: { fields: publishedAt, order: DESC }) {
      nodes {
        newsId
        title
        publishedAt(locale: "ja-JP", formatString: "YYYY.MM.DD")
        category {
          title
        }
        body
        thumbnail {
          url
        }
      }
    }
  }
`;
