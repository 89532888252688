import React, { useEffect, useMemo, useRef, useState } from 'react';
import HEADER_SP from '../../../static/top_page_header_logo-sp.svg';
import HEADER from '../../../static/top_page_header_logo.svg';
import BG_IMAGE from '../../images/ui_circle_image.png';
import { css } from '@emotion/react';
import { mediaSp, spWidth } from '../../styles/media-query';

export default function Hero() {
  const mounted = useRef(false);
  const [isLoaded, setLoaded] = useState(false);
  const [{ scrollTop }, setScroll] = useState({ scrollTop: 0 });
  const [{ vh, vw }, setViewPort] = useState({ vh: 0, vw: 0 });
  const [innerHeight, setInnerHeight] = useState(0);

  useEffect(() => {
    if (mounted.current) {
      setLoaded(true);
    }
    mounted.current = true;
  }, [mounted.current]);

  useEffect(() => {
    const viewPortHandler = () =>
      setViewPort({ vh: window.innerHeight, vw: window.innerWidth });
    window.addEventListener('resize', viewPortHandler);
    const scrollHandler = () => setScroll({ scrollTop: window.scrollY });
    window.addEventListener('scroll', scrollHandler);
    setInnerHeight(window.innerHeight);

    viewPortHandler();
    scrollHandler();

    return () => {
      window.removeEventListener('resize', viewPortHandler);
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  const animationBlakePoint = useMemo(() => {
    if (spWidth < vw) {
      return vh * 0.72 < scrollTop;
    }
    return vh * 0.72 - 60 < scrollTop;
  }, [vh, vw, scrollTop]);

  const isHiddenScrollDown = useMemo(() => {
    return 70 < scrollTop;
  }, [scrollTop]);

  return (
    <section
      css={css`
        position: relative;
        height: 148vh;
        box-sizing: content-box;
        background-color: #f0f2f5;
        ${mediaSp} {
          height: ${innerHeight * 1.5}px;
          // vhが入っていない場合に高さが固定になる
          ${!vh && 'height: 148vh;'}
        }
      `}
    >
      <img
        src={BG_IMAGE}
        alt=""
        css={css`
          position: fixed;
          z-index: 0;
          top: ${innerHeight * 0.5}px;
          left: 50%;
          height: 652px;
          pointer-events: none;
          width: 688px;
          opacity: 1;
          transform: translate3d(-50%, -50%, 0) scale(0.93);
          ${mediaSp} {
            width: 375px;
            height: 354px;
          }

          &[data-transition='true'] {
            transition: transform 0.7s, opacity 0.7s;
            will-change: transform, opacity;
          }

          &[data-animation='true'] {
            opacity: 0;
            transform: translate(-50%, -50%);
          }
        `}
        data-animation={animationBlakePoint}
        data-transition={isLoaded}
      />
      <div
        css={css`
          position: absolute;
          z-index: 1;
          top: 50vh;
          left: 50%;
          width: 100%;

          text-align: center;
          transform: translate(-50%, -50%);

          ${mediaSp} {
            margin-top: -15px;
          }
        `}
      >
        <img
          src={HEADER}
          alt="Governance Growth"
          css={css`
            display: block;
            margin: auto;
            ${mediaSp} {
              display: none;
            }
          `}
        />
        <img
          src={HEADER_SP}
          alt="Governance Growth"
          css={css`
            display: none;
            ${mediaSp} {
              display: block;
              margin: auto;
              width: 252.96px;
              height: 72.05px;
            }
          `}
        />
        <p
          css={css`
            margin-top: 20px;

            font-size: 16px;
            letter-spacing: 0.24em;
            line-height: 1.5;

            ${mediaSp} {
              font-size: 12px;
              font-weight: 300;
              letter-spacing: 2.88px;
              line-height: 18px;
            }
          `}
        >
          企業経営に持続的成長の基盤となる
          <br className="sp-only" />
          ガバナンスを
        </p>
      </div>
      <div
        css={css`
          position: absolute;
          z-index: 1;
          bottom: calc(48vh + 10px);
          left: 50%;
          height: 90px;
          opacity: 1;
          transition: opacity 0.67s;

          ${mediaSp} {
            bottom: inherit;
            top: ${innerHeight - 100}px;
          }

          &[data-hidden='true'] {
            opacity: 0;
          }

          span {
            position: absolute;
            top: -20px;
            left: -22px;

            font-size: 1.4rem;
            letter-spacing: 0.08em;
            transform: rotate(-90deg);
          }

          &::after {
            position: absolute;
            top: 20px;
            width: 1px;
            height: 80px;
            background: #252a40;
            content: '';
            opacity: 1;
          }
        `}
        data-hidden={isHiddenScrollDown}
      >
        <span>scroll</span>
      </div>
    </section>
  );
}
