import React from 'react';
import { mediaSp } from '../styles/media-query';
import { css } from '@emotion/react';

export const SectionTitle = ({
  enText,
  titleText,
  as: Tag = 'h2',
  ...props
}) => {
  return (
    <div {...props}>
      <div
        css={css`
          position: relative;
          margin-left: -35px;

          ${mediaSp} {
            margin-left: 20px;
          }
        `}
      >
        <p
          css={css`
            margin: 0;
            color: #fff;
            font-family: 'Noto Sans JP', sans-serif;
            font-size: 136px;
            font-weight: 900;
            letter-spacing: 13.6px;
            line-height: 197px;
            opacity: 1;

            ${mediaSp} {
              font-size: 100px;
              letter-spacing: 5.04px;
              line-height: 1;

              margin-right: 60%;
              white-space: normal;

              @media (max-width: 550px) {
                font-size: 56px;
              }
            }
          `}
        >
          {enText}
        </p>
        <Tag
          css={css`
            position: absolute;
            bottom: 28px;
            left: 94px;
            margin: 0;
            color: #6c6f7e;
            font-family: 'Noto Sans JP', sans-serif;
            font-size: 35px;
            font-weight: bold;
            letter-spacing: 3.5px;
            line-height: 51px;

            ${mediaSp} {
              bottom: -16px;
              left: 15px;
              font-size: 20px;
              letter-spacing: 2px;
              line-height: 29px;
            }
          `}
        >
          {titleText}
        </Tag>
      </div>
    </div>
  );
};
